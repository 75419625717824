import React from "react";


export default function Apresentacao(){
    return(
        <>
        <section className="about-one" style={{"background-color":"#000","color":"#FFFFFF !important"}}>
        <div className="container">
            <div className="row">
            <div className="col-xl-4" />
            <div className="col-xl-4">
                <div className="row">
                <div className="col-md-12" style={{"display":"flex","-webkit-flex-direction":"row","-ms-flex-direction":"row","flex-direction":"row","-webkit-box-pack":"center","-webkit-justify-content":"center","-ms-flex-pack":"center","justify-content":"center"}}>
                    <img src="assets/images/logo.png" style={{"width":"50%"}} /> 
                </div>
                <div className="col-md-12" style={{"margin-top":"20px","-webkit-text-align":"center","text-align":"center"}}> 
                    <p><i className="fas fa-map-marker" /> &nbsp;&nbsp;Barão de Santo Ângelo 118 - Centro de Canoas</p>
                    <p style={{"margin-top":"30px","font-size":"20px"}}>O SEU SORRISO</p> 
                    <p style={{"margin-top":"-20px","font-size":"20px"}}>É O QUE NOS MOVE</p>
                    <div className=" wow fadeInLeft animated animated" data-wow-delay="50ms" style={{"visibility":"visible","-webkit-animation-delay":"50ms","animation-delay":"50ms","-webkit-animation-name":"_fadeInRight","animation-name":"_fadeInRight","padding":"56.25% 0px 0px","position":"relative"}}><iframe src="https://player.vimeo.com/video/396041805?h=aec8d20f9c&title=0&byline=0&portrait=0" style={{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"}} frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen data-ready="true" /></div>
                    <a href="https://formandos.operaprodutora.com.br/" className="nisoz-btn wow fadeInLeft animated animated" data-wow-delay="100ms" style={{"visibility":"visible","-webkit-animation-delay":"100ms","animation-delay":"100ms","-webkit-animation-name":"_fadeInRight","animation-name":"_fadeInRight","width":"100%","margin-top":"40px","background-color":"rgb(197, 52, 104)","color":"rgb(255, 255, 255)","border-radius":"10px !important"}} target="_blank" rel="noreferrer">
                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                    <span className="nisoz-btn__text">
                        <i className="fas fa-user-graduate" style={{"float":"left","padding-top":"3px","font-size":"25px"}} />
                        Área do Formando
                    </span>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=5551997337941&text=Tenho%20interesse%20em%20um%20or%C3%A7amento:%0AFaculdade:%20%0ACurso:%0AQuantidade%20de%20formandos:%0AAno:%0ASemestre:%0A" className="nisoz-btn wow fadeInLeft animated animated" data-wow-delay="200ms" style={{"visibility":"visible","-webkit-animation-delay":"200ms","animation-delay":"200ms","-webkit-animation-name":"_fadeInRight","animation-name":"_fadeInRight","width":"100%","margin-top":"40px","background-color":"rgb(197, 52, 104)","color":"rgb(255, 255, 255)","border-radius":"10px !important"}} target="_blank" rel="noreferrer">
                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                    <span className="nisoz-btn__text">
                        <i className="fab fa-whatsapp" style={{"float":"left","padding-top":"3px","font-size":"25px"}} />
                        Fale com a gente
                    </span>
                    </a>
                    <a href="https://www.instagram.com/operaprodutora/" className="nisoz-btn wow fadeInLeft animated animated" data-wow-delay="300ms" style={{"visibility":"visible","-webkit-animation-delay":"300ms","animation-delay":"300ms","-webkit-animation-name":"_fadeInRight","animation-name":"_fadeInRight","width":"100%","margin-top":"40px","background-color":"rgb(197, 52, 104)","color":"rgb(255, 255, 255)","border-radius":"10px !important"}} target="_blank" rel="noreferrer">
                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                    <span className="nisoz-btn__text">
                        <i className="fab fa-instagram" style={{"float":"left","padding-top":"3px","font-size":"25px"}} />
                        Instagram
                    </span>
                    </a>
                    <a href="https://vimeo.com/operaprodutora" className="nisoz-btn wow fadeInLeft animated" data-wow-delay="400ms" style={{"visibility":"hidden","-webkit-animation-delay":"400ms","animation-delay":"400ms","-webkit-animation-name":"_none","animation-name":"_none","width":"100%","margin-top":"40px","background-color":"rgb(197, 52, 104)","color":"rgb(255, 255, 255)","border-radius":"10px !important"}} target="_blank" rel="noreferrer">
                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                    <span className="nisoz-btn__text">
                        <i className="fab fa-vimeo" style={{"float":"left","padding-top":"3px","font-size":"25px"}} />
                        Vimeo
                    </span>
                    </a>  
                    <a href="https://www.facebook.com/OperaProdutora" className="nisoz-btn wow fadeInLeft animated" data-wow-delay="500ms" style={{"visibility":"hidden","-webkit-animation-delay":"500ms","animation-delay":"500ms","-webkit-animation-name":"_none","animation-name":"_none","width":"100%","margin-top":"40px","background-color":"rgb(197, 52, 104)","color":"rgb(255, 255, 255)","border-radius":"10px !important"}} target="_blank" rel="noreferrer">
                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                    <span className="nisoz-btn__text">
                        <i className="fab fa-facebook" style={{"float":"left","padding-top":"3px","font-size":"25px"}} />
                        Facebook
                    </span>
                    </a>
                </div>
                </div>
            </div> 
            </div>
        </div>
        </section>
        </>
    )
}