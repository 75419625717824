import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Apresentacao from "./pages/apresentacao";

export default function App() {

  return (
    <BrowserRouter>
      <Helmet>
        <script src="assets/js/nisoz.js" type="text/javascript" />
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Apresentacao />}></Route>
        <Route path="*" element={<Apresentacao />}></Route>
      </Routes>
    </BrowserRouter>
  );
}